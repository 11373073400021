









































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import XSolid from '~/components/icons/XSolid.vue';
import LanguageSwitcher from '~/components/partials/LanguageSwitcher.vue';
import MenuCard from '~/components/cards/MenuCard.vue';
import ArrowNarrowRightSolid from '~/components/icons/ArrowNarrowRightSolid.vue';
import SearchOutline from '~/components/icons/SearchOutline.vue';
import NewsletterModal from "~/components/modals/NewsletterModal.vue";
import MobileDownloadFixedFooter from "~/components/modals/MobileDownloadFixedFooter.vue";

@Component({
  components: {
    MobileDownloadFixedFooter,
    NewsletterModal,
    LanguageSwitcher,
    XSolid,
    MenuCard,
    ArrowNarrowRightSolid,
    SearchOutline,
  },
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
      'showNewsletterModal',
      'iframeMode',
    ]),
    ...mapState('explorer', [
      'displayGlobalSearchInHeader',
      'destination',
      'displayLocalisationMap',
    ]),
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class TopMenu extends Vue {
  @Prop(Boolean) hideLogoAndSearch: false;
  @Prop(Boolean) hideTopMenuWhenMapOpen: boolean;

  globalSearchModalIsDisplayed: boolean;
  showNewsletterModal: boolean;
  displayGlobalSearchInHeader: boolean;
  displayLocalisationMap : boolean;
  destination: Types.DestinationItem;
  locale: string;
  userIsConnected: boolean = false;
  displayShadow: boolean = false;
  showMobileDownloadFixedFooter: boolean = false;
  isMobileDevice: boolean = false;
  // TODO is menuItems really used
  menuItems = [
    {
      name: this.$t('topMenu.menuItems.home'),
      link: '/',
    },
    {
      name: this.$t('topMenu.menuItems.explore'),
      link: '/explorer',
    },
    // {
    //   name: this.$t('topMenu.menuItems.blog'),
    //   link: '/blog',
    // },
  ];

  /*
    If we are in a destination context, the logo isn't displayed, showing
    destination name instead. The link will be updated then.
  */
  get destinationPath () {
    if (this.$route.params?.destinationid) {
      return `/explorer/destinations/${this.$route.params.destinationid}`;
    }
    return '/';
  }

  /*
    This computed will update the style of the menu only if it isn't home page
    or in special store value
  */
  get globalSearchBarDisplay () {
    return (this.$route?.name && !this.$route.name.includes(`index___${this.locale}`)) || this.displayGlobalSearchInHeader;
  }

  get displayTopMenu () {
    return this.hideTopMenuWhenMapOpen ? !this.displayLocalisationMap : true;
  }
  // get spanToDisplay () {
  //   if (
  //     this.isInSignUpProcess &&
  //     this.$route.name !== `signup-experiences___${this.locale}` &&
  //     this.$route.name !== `signup-experiences___${this.locale}___default` &&
  //     this.$route.name !== `signup-informations___${this.locale}` &&
  //     this.$route.name !== `signup-informations___${this.locale}___default`
  //   ) {
  //     return this.$t('topMenu.goToSignInItem');
  //   }
  //   if (this.isInSignInProcess) {
  //     return this.$t('topMenu.goToSignUpItem');
  //   }
  //   return '';
  // }

  /* Checks if page is in a blog context (layout / page) */
  get isInBlogContext () {
    return this.$route.path.includes('/blog');
  }

  /* Checks if page is in a sign in context (page) */
  get isInSignInProcess () {
    return this.$route.path.includes('signin');
  }

  /* Checks if page is in a account context (layout / page) */
  get isInResetProcess () {
    return this.$route.path.includes('/account/');
  }

  /* Checks if page is in a signup context (layout / page) */
  get isInSignUpProcess () {
    return this.$route.path.includes('signup');
  }

  /*
    If globalSearchModal is open, we hide the menu
    If we are in explorer context, we don't want to have a sticky menu as
    specific behavior are expected especiallt for destination/routeDetails
  */
  get menuDisplay () {
    if (this.globalSearchModalIsDisplayed) {
      return 'hidden';
    }
    if (this.$route.path.includes('/explorer/')) {
      return '';
    }
    return 'sticky';
  }

  /*
    Everytime route is changed, the menu can have micro-seconds of transition
    updating the menu. We ensure the menu style doesn't "spam updates" all
    the time
  */
  @Watch('$route.path')
  checkUserConnection () {
    this.$cookies.get('token') ? this.userIsConnected = true : this.userIsConnected = false;
  }

  /*
    Triggers this.checkUserConnection one time in created as we can't watch
    this.$route.path when creating a page
  */
  created () {
    this.checkUserConnection();
  }

  mounted () {
    // Listens to the scroll of the page to add some style based on user scroll
    if (process.client) window.addEventListener('scroll', this.displayMenuShadow);

    // Force reset the menu style in some edge-cases, especially signing out if
    // the app isn't fast enough to handle user status
    this.$root.$on('force-menu-refresh', () => {
      this.userIsConnected = false;
    });
    // check if mobile download modal was already close on this session
    this.checkMobileDownloadFixedFooterStatus();
    this.isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  }

  checkMobileDownloadFixedFooterStatus (): void {
    const fixedFooterClosed = sessionStorage.getItem('mobileDownloadFixedFooterClosed');
    if (!fixedFooterClosed) {
      this.showMobileDownloadFixedFooter = true;
    }
  }

  beforeDestroy () {
    if (process.client) window.removeEventListener('scroll', this.displayMenuShadow);
  }

  /* If user has scrolled 30px, we add a little shadow on the menu */
  displayMenuShadow () {
    if (process.client) {
      if (window.scrollY >= 30) {
        this.displayShadow = true;
      } else {
        this.displayShadow = false;
      }
    }
  }
}
