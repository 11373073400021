


































import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import SettingsSolid from '~/components/icons/SettingsSolid.vue';
import Avatar from '~/components/cards/Avatar.vue';

@Component({
  components: { SettingsSolid, Avatar },
  props: {
    userInfos: Object,
    previewing: Boolean,
  },
  computed: {
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class ProfileBanner extends Vue {
  locale: string;

  /* Ensure actual page isn't runDetailsEdit page, to update template elements */
  get isNotEditRunPage () {
    return this.$route.name !== `profile-my-runs-runid-edit___${this.locale}` ||
    this.$route.name !== `profile-my-runs-runid-edit___${this.locale}___default`;
  }

  /* Handles style of active link */
  linkStyleToDisplay (link: string) {
    if (this.$route.path.includes(link)) {
      return 'text-gray-default font-semibold';
    }
    return 'text-gray-400 font-semibold';
  }

  /* ⬆️ */
  linkHintToDisplay (link: string) {
    if (this.$route.path.includes(link)) {
      return 'bg-primary-default border-primary-default';
    }
    return 'bg-white border-white';
  }
};
