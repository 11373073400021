import { Middleware } from '@nuxt/types';

const authMiddleware: Middleware = async ({ store, route, redirect, app }) => {
  // check if skipAuth is present, if it is, don't check auth (for example, for activity preview pages)
  if (route.meta.some((meta: any) => meta.skipAuth)) {
    return;
  }

  console.info('[authMiddleware]');
  store.commit('SET_GLOBALSEARCH_DISPLAY', false);

  /*
    This condition ensure user to always have a cookie value for
    the preferred unit
  */
  if (!app.$cookies.get('unit')) {
    if (app.i18n.getBrowserLocale() === 'en-US') app.$cookies.set('unit', 'imperial');
    else app.$cookies.set('unit', 'metric');
  }

  var notAuthenticated = true;

  /*
    The middleware will try to see :
    If token bearer provided when signin is correct, then ;
    If loadProfile action returns a defined object, then ;
    User will be considered connected. If not, and based on actual route,
    will be redirected.
  */
  if (await store.dispatch('user/loadToken')) {
    if (await store.dispatch('user/loadProfile', { useCache: true })) {
      notAuthenticated = false;
    }
  }

  if (notAuthenticated) {
    if (route.name) {
      if (
        route.name.includes('signup-experiences') ||
        route.name.includes('signup-informations') ||
        route.name.includes('signup-profile')
      ) {
        return;
        // in case of register error we want to handle it on their own page
        // return redirect('/signup/email');
      }
      return redirect(app.localePath('/signin?signout=1'));
    }
  }
};

export default authMiddleware;
