import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vuesocialsharingplugin_097f1e3f from 'nuxt_plugin_vuesocialsharingplugin_097f1e3f' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_dayjsplugin_a5d5c852 from 'nuxt_plugin_dayjsplugin_a5d5c852' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtleaflet_97ac5882 from 'nuxt_plugin_nuxtleaflet_97ac5882' // Source: ./nuxt-leaflet.js (mode: 'client')
import nuxt_plugin_tooltip_69814e2b from 'nuxt_plugin_tooltip_69814e2b' // Source: ../plugins/tooltip.js (mode: 'all')
import nuxt_plugin_i18n_3032693c from 'nuxt_plugin_i18n_3032693c' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_index_02cba674 from 'nuxt_plugin_index_02cba674' // Source: ../plugins/social/index.ts (mode: 'all')
import nuxt_plugin_axios_54e49ad0 from 'nuxt_plugin_axios_54e49ad0' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_dayjsextends_4a154506 from 'nuxt_plugin_dayjsextends_4a154506' // Source: ../plugins/dayjs-extends.js (mode: 'all')
import nuxt_plugin_ssrplugins_120dd089 from 'nuxt_plugin_ssrplugins_120dd089' // Source: ../plugins/ssr-plugins.js (mode: 'all')
import nuxt_plugin_externalvueplugins_1e868189 from 'nuxt_plugin_externalvueplugins_1e868189' // Source: ../plugins/external-vue-plugins.js (mode: 'client')
import nuxt_plugin_directives_5f2656fc from 'nuxt_plugin_directives_5f2656fc' // Source: ../plugins/directives.js (mode: 'client')
import nuxt_plugin_geoloc_251983ca from 'nuxt_plugin_geoloc_251983ca' // Source: ../plugins/geo-loc.js (mode: 'client')
import nuxt_plugin_axiosinstances_48a8cac7 from 'nuxt_plugin_axiosinstances_48a8cac7' // Source: ../plugins/axios-instances.js (mode: 'all')
import nuxt_plugin_slugify_0c46f6fd from 'nuxt_plugin_slugify_0c46f6fd' // Source: ../plugins/slugify.js (mode: 'all')
import nuxt_plugin_mediaresize_21c70563 from 'nuxt_plugin_mediaresize_21c70563' // Source: ../plugins/media-resize.js (mode: 'all')
import nuxt_plugin_newsletterTrigger_27eb4d2f from 'nuxt_plugin_newsletterTrigger_27eb4d2f' // Source: ../plugins/newsletterTrigger.js (mode: 'all')
import nuxt_plugin_iframe_17228248 from 'nuxt_plugin_iframe_17228248' // Source: ../plugins/iframe.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    return this.$root.$options.$nuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    let i18nHead = {};
    if (this && this?.$nuxtI18nHead) {
      i18nHead = this?.$nuxtI18nHead({ addSeoAttributes: true });
    }

    return {
      htmlAttrs: {
        ...(i18nHead.htmlAttrs || [])
      },
      title: 'On Piste',
      meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ...(i18nHead.meta || []),
      // { hid: 'description', name: 'description', content: this._i18n?.messages[this?.$store?.state?.lang?.locale]['meta.description'] },
      process.env.DISABLE_ROBOTS_SEO ? { name: 'robots', content: 'noindex, nofollow, nosnippet, noarchive' } : {}],

      link: [
      { rel: 'icon', type: 'image/x-icon', href: '/img/logos/badge__white.png' },
      ...(i18nHead.link || [])]
    };
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_097f1e3f === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_097f1e3f(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_a5d5c852 === 'function') {
    await nuxt_plugin_dayjsplugin_a5d5c852(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtleaflet_97ac5882 === 'function') {
    await nuxt_plugin_nuxtleaflet_97ac5882(app.context, inject)
  }

  if (typeof nuxt_plugin_tooltip_69814e2b === 'function') {
    await nuxt_plugin_tooltip_69814e2b(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_3032693c === 'function') {
    await nuxt_plugin_i18n_3032693c(app.context, inject)
  }

  if (typeof nuxt_plugin_index_02cba674 === 'function') {
    await nuxt_plugin_index_02cba674(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_54e49ad0 === 'function') {
    await nuxt_plugin_axios_54e49ad0(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsextends_4a154506 === 'function') {
    await nuxt_plugin_dayjsextends_4a154506(app.context, inject)
  }

  if (typeof nuxt_plugin_ssrplugins_120dd089 === 'function') {
    await nuxt_plugin_ssrplugins_120dd089(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_externalvueplugins_1e868189 === 'function') {
    await nuxt_plugin_externalvueplugins_1e868189(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directives_5f2656fc === 'function') {
    await nuxt_plugin_directives_5f2656fc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_geoloc_251983ca === 'function') {
    await nuxt_plugin_geoloc_251983ca(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosinstances_48a8cac7 === 'function') {
    await nuxt_plugin_axiosinstances_48a8cac7(app.context, inject)
  }

  if (typeof nuxt_plugin_slugify_0c46f6fd === 'function') {
    await nuxt_plugin_slugify_0c46f6fd(app.context, inject)
  }

  if (typeof nuxt_plugin_mediaresize_21c70563 === 'function') {
    await nuxt_plugin_mediaresize_21c70563(app.context, inject)
  }

  if (typeof nuxt_plugin_newsletterTrigger_27eb4d2f === 'function') {
    await nuxt_plugin_newsletterTrigger_27eb4d2f(app.context, inject)
  }

  if (typeof nuxt_plugin_iframe_17228248 === 'function') {
    await nuxt_plugin_iframe_17228248(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
