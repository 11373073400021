import { snakeCase } from 'lodash';

export default ({ store, route }) => {
  const queryParams = route.query; // Get query parameters from SSR context

  const modes = {
    iframe: 'iframe',
    iframeRoutePub: 'pub',
    iframeRouteDescription: 'description',
    iframeRouteBackSearch: 'backSearch',
    iframeRouteHeader: 'header',
  };

  Object.entries(modes).forEach(([mode, prefix]) => {
    if (!(mode in queryParams)) return;

    const extractedParams = Object.keys(queryParams)
      .filter(param => param.startsWith(prefix))
      .reduce((acc, val) => {
        const unprefixedKey = val.replace(prefix, '');
        acc[unprefixedKey] = queryParams[val];
        return acc;
      }, {});

    if (extractedParams.sport) {
      extractedParams.sport = parseInt(extractedParams.sport);
    }

    const mutationName = `SET_${snakeCase(mode).toUpperCase()}_MODE`;

    store.commit(mutationName, extractedParams);
  });
};
