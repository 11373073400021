






















import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import TopMenu from '~/components/menus/TopMenu.vue';
import ProfileBanner from '~/components/lists/ProfileBanner.vue';
import GlobalSearch from '~/components/modals/GlobalSearch.vue';

@Component({
  components: { TopMenu, ProfileBanner, GlobalSearch },
  middleware: 'auth',
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
    ]),
    userInfos: {
      get () {
        // if user is passed, use it, otherwise use user me
        return this.$store.state.activities.focusedActivityPreviewUser ||
          this.$store.state.user.me;
      },
    },
    previewing: {
      get () {
        return !!this.$store.state.activities.focusedActivityPreviewUser;
      },
    },
  },
})
export default class SignInLayout extends Vue {

}
